<template>
  <div class="c-headingBand">
    {{ txt }}
  </div>
</template>

<script>
export default {
  name: "HeadingBand",
  props: {
    txt: String,
  },
};
</script>

<style scoped lang="scss">
.c-headingBand {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  font-size: 16px;
  color: $white;
  background-color: $black;
}
</style>
