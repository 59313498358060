import {
  createRouter,
  createWebHistory
} from "vue-router"

import VisitorCallModalCall from "../views/VisitorCallModalCall.vue";
import VisitorCompanyPost from "../views/VisitorCompanyPost.vue";
import VisitorPost from "../views/VisitorPost.vue";
import VisitorPostDone from "../views/VisitorPostDone.vue";
import MemberStatus from "../views/MemberStatus.vue";
import PostNotify from "../views/PostNotify.vue";
import CallJeki from "../views/CallJeki.vue";
import Me from "../views/me.vue";

const routes = [{
    path: "/",
    name: "VisitorTop",
    component: () => import("../views/VisitorTop.vue")
  },
  {
    path: "/visitor_company_list",
    name: "VisitorCompanyList",
    component: () => import("../views/VisitorCompanyList.vue")
  },
  {
    path: "/visitor_member_list",
    name: "VisitorMemberList",
    component: () => import("../views/VisitorMemberList.vue")
  },
  {
    path: "/visitor_call_modal",
    name: "VisitorCallModal",
    component: () => import("../views/VisitorCallModal.vue")
  },
  {
    path: "/visitor_call_modal_call",
    name: "VisitorCallModalCall",
    component: VisitorCallModalCall
  },
  {
    path: "/visitor_company_post",
    name: "VisitorCompanyPost",
    component: VisitorCompanyPost
  },
  {
    path: "/visitor_post",
    name: "VisitorPost",
    component: VisitorPost
  },
  {
    path: "/visitor_post_done",
    name: "VisitorPostDone",
    component: VisitorPostDone
  },
  {
    path: "/member_top",
    name: "MemberTop",
    component: () => import("../views/MemberTop.vue")
  },
  {
    path: "/member_status",
    name: "MemberStatus",
    component: MemberStatus
  },
  {
    path: "/member_status_change",
    name: "MemberStatusChange",
    component: () => import("../views/MemberStatusChange.vue")
  },
  {
    path: "/member_call",
    name: "MemberCall",
    component: () => import("../views/MemberCall.vue")
  },
  {
    path: "/post_notify",
    name: "PostNotify",
    component: PostNotify
  },
  {
    path: "/call_jeki",
    name: "CallJeki",
    component: CallJeki
  },
  {
    path: "/me",
    name: "me",
    component: Me
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
