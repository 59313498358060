<template>
  <div class="l-header">
    <router-link :to="home" class="l-header_logo">
      <img v-if="$route.path !== '/'" class="" src="@/assets/img/logo.svg" />
    </router-link>
    <div class="l-header_clock"><Clock /></div>
  </div>
</template>

<script>
import Clock from "@/components/Clock.vue";

export default {
  name: "Header",
  components: {
    Clock,
  },
  data: () => ({
    home: "/",
  }),
  mounted() {
    this.home =
      localStorage.getItem("type") === "visitor" ? "/" : "/member_top";
  },
};
</script>

<style scoped lang="scss">
.l-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  height: 91px;

  &_logo {
    width: 120px;
    height: 40px;
  }
}
</style>
