<template>
  <router-link :to="`${link}`" class="c-btnMedium" :class="`${colorClass}`">
    <p class="c-btnMedium_txt">{{ txt }}</p>
  </router-link>
</template>

<script>
export default {
  name: "BtnMedium",
  props: {
    txt: String,
    link: String,
    color: {
      type: String,
      default: "black",
    },
  },
  computed: {
    // 背景色 class の出力
    colorClass() {
      const color = this.color;
      let className;
      switch (color) {
        case "blue": {
          className = "c-btnMedium-blue";
          break;
        }
        case "gray": {
          className = "c-btnMedium-gray";
          break;
        }
        case "orange": {
          className = "c-btnMedium-orange";
          break;
        }
        default: {
          className = "";
        }
      }
      return className;
    },
  },
};
</script>

<style lang="scss">
.c-btnMedium {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #0e0000;
  border-radius: 11px;
  width: 357px;
  height: 78px;
  background-color: $black;

  &-blue {
    border-color: $blue;
    background-color: $blue;
  }

  &-gray {
    border-color: $gray;
    background-color: $gray;
  }

  &-orange {
    border-color: $orange;
    background-color: $orange;
  }

  &_txt {
    color: $white;
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
