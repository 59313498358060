<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-memberStatusChange">
          <div class="l-memberStatusChange_txt">
            在席ステータスを変更します。
          </div>

          <BtnMedium
            :txt="text1"
            :link="
              `/member_status_change?id=${id}&before=${status}&after=${type1}`
            "
            class="l-memberStatusChange_btnMedium"
            :color="color1"
          />

          <BtnMedium
            :txt="text2"
            :link="
              `/member_status_change?id=${id}&before=${status}&after=${type2}`
            "
            class="l-memberStatusChange_btnMedium"
            :color="color2"
          />

          <router-link to="/member_top" class="l-memberStatusChange_cancel">
            <div></div>
            <div></div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnMedium from "@/components/BtnMedium.vue";

export default {
  name: "MemberStatus",
  components: {
    BtnMedium,
  },
  data: () => ({
    id: "",
    status: "",
    text1: "---",
    color1: "gray",
    type1: 0,
    text2: "---",
    color2: "gray",
    type2: 0,
  }),
  mounted() {
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;

    switch (this.status) {
      case "0":
        this.text1 = "在席中";
        this.color1 = "blue";
        this.type1 = 1;
        this.text2 = "来客中";
        this.color2 = "orange";
        this.type2 = 2;
        break;
      case "1":
        this.text1 = "不在";
        this.color1 = "gray";
        this.type1 = 0;
        this.text2 = "来客中";
        this.color2 = "orange";
        this.type2 = 2;
        break;
      case "2":
        this.text1 = "不在";
        this.color1 = "gray";
        this.type1 = 0;
        this.text2 = "在席中";
        this.color2 = "blue";
        this.type2 = 1;
        break;
      default:
        break;
    }
  },
};
</script>

<style scoped lang="scss">
.l-memberStatusChange {
  position: relative;
  padding: 0 30px 25px;

  &_txt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 241px;
    text-align: center;
    line-height: 1;
    font-size: 18px;
  }

  &_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 90px;

    &_arrow {
      width: 31px;
      height: 15px;
    }
  }

  &_btnMedium {
    &:first-of-type {
      margin-bottom: 40px;
    }
  }

  &_btnSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto 0;
    border: 1px solid $black;
    border-radius: 11px;
    width: 187px;
    height: 60px;
  }

  &_cancel {
    position: absolute;
    box-sizing: border-box;
    padding: 10px;
    width: 50px;
    height: 50px;
    top: 15px;
    right: 15px;

    div {
      position: absolute;
      width: 100%;
      height: 0;
      border-top: 2px solid #000000;
      transform-origin: 50% 50%;
      top: 50%;
      left: 50%;

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
</style>
