<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-visitorCall">
          <div class="comp">
            <img :src="logo" />
            {{ name }}
          </div>

          <div class="l-visitorCall_btn">
            <BtnMedium
              txt="呼び出す"
              link="/visitor_post_done"
              @click="submit"
            />
            <BtnSmallBack
              txt="訪問先一覧に戻る"
              link="/visitor_company_post"
              class="l-visitorCall_btn_back"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import BtnMedium from "@/components/BtnMedium.vue";
import BtnSmallBack from "@/components/BtnSmallBack.vue";

export default {
  name: "VisitorPost",
  components: {
    BtnMedium,
    BtnSmallBack,
  },
  data: () => ({
    id: "",
    logo: "",
    name: "",
  }),
  mounted() {
    this.id = localStorage.getItem("comp_id");
    this.logo = localStorage.getItem("comp_logo");
    this.name = localStorage.getItem("comp_name");
  },
  methods: {
    async submit() {
      await axios.post(
        "/status.php",
        JSON.stringify({
          s: "p",
          t: this.id,
        })
      );
    },
  },
};
</script>

<style scoped lang="scss">
.l-visitorCall {
  position: relative;
  height: 100%;

  &_btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.comp {
  display: flex;
  padding: 90px 0;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;

  img {
    display: block;
    margin-right: 16px;
    width: 60px;
    height: 60px;
  }
}
</style>
