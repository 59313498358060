<template>
  <time class="c-clock">{{ time }}</time>
</template>

<script>
export default {
  name: "Clock",
  data: function () {
    return {
      time: "00:00",
    };
  },
  mounted: function () {
    // 表示時間を1秒ごとに更新
    setInterval(this.updateTime, 1000);
  },
  methods: {
    // 現在時刻を取得・表示
    updateTime: function () {
      let currentDate = new Date();
      this.time =
        this.zeroPadding(currentDate.getHours()) +
        ":" +
        this.zeroPadding(currentDate.getMinutes());
    },

    // 時・分が1桁の時に、十の位に「0」を付与
    zeroPadding: function (num) {
      return ("00" + num).slice(-2);
    },
  },
};
</script>

<style scoped lang="scss">
.c-clock {
  @include poppins;
  font-size: 30px;
}
</style>
