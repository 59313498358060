<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-visitorCall">
          <VisitorCallModalJeki :nameJa="ja" :img="img" v-if="isJeki" />
          <VisitorCallModalMember :nameJa="ja" :nameEn="en" :img="img" v-else />

          <p class="l-visitorCall_txt">{{ state }}</p>

          <div v-if="phase === 0">
            <div class="l-visitorCall_animation">
              <div class="loader-inner ball-spin-fade-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div class="l-visitorCall_hangUp">
              <div
                class="l-visitorCall_hangUp_btn"
                @click="disconnect"
                v-if="canCancel"
              >
                <img
                  class="l-visitorCall_hangUp_btn_img"
                  src="@/assets/img/tel-hangup.svg"
                />
              </div>
            </div>
          </div>

          <div v-if="phase === 1">
            <div class="l-visitorCall_animation">
              <div class="loader-inner line-scale-pulse-out-rapid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div class="l-visitorCall_hangUp">
              <div class="l-visitorCall_hangUp_btn" @click="disconnect">
                <img
                  class="l-visitorCall_hangUp_btn_img"
                  src="@/assets/img/tel-hangup.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="video" class="video"></div>
    <video id="me" class="me" autoplay muted></video>
  </div>
</template>

<script>
import axios from "axios";
import { connect } from "twilio-video";

import VisitorCallModalJeki from "@/components/VisitorCallModalJeki.vue";
import VisitorCallModalMember from "@/components/VisitorCallModalMember.vue";

var _room;

const wait = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: "VisitorCalll",
  components: {
    VisitorCallModalJeki,
    VisitorCallModalMember,
  },
  data: () => ({
    state: "呼び出し中です",
    phase: 0,
    ja: "",
    en: "",
    img: "",
    canCancel: false,
    isJeki: false,
  }),
  async mounted() {
    state = "wait"; // eslint-disable-line

    window.addEventListener("beforeunload", () => _room?.disconnect());

    this.ja = localStorage.getItem("ja");
    this.en = localStorage.getItem("en");
    this.img = localStorage.getItem("img");

    this.isJeki = this.en === "jeki";

    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        const video = document.getElementById("me");
        video.srcObject = stream;
      });

    const comp_id = localStorage.getItem("comp_id");
    const memb_id = localStorage.getItem("memb_id");

    if (this.isJeki) {
      await axios.post(
        "/status.php",
        JSON.stringify({
          s: "cs",
          t: "A",
        })
      );
    } else {
      await axios.post(
        "/status.php",
        JSON.stringify({
          s: "cs",
          t: comp_id,
          m: memb_id,
        })
      );
    }

    const { data } = await axios.get("/token.php");
    connect(data.token, {
      name: "VideoRoom",
      video: true,
      audio: true,
    }).then(async (room) => {
      this.canCancel = true;

      _room = room;

      room.participants.forEach(this.participantConnected);
      room.on("participantConnected", this.participantConnected);
      room.on("participantDisconnected", this.participantDisconnected);
    });

    while (true) { // eslint-disable-line
      if (state === "connected") { // eslint-disable-line
        this.phase++;
        this.state = "通話中";
        break;
      }
      await wait(500);
    }
  },
  methods: {
    changePhase() {
      this.phase++;
    },
    async disconnect() {
      await axios.post(
        "/status.php",
        JSON.stringify({
          s: "ce",
          t: this.comp_id,
        })
      );
      _room.disconnect();
      _room = null;
      this.$router.push("/");
    },
    participantConnected(rp) {
      rp.on("trackSubscribed", this.onTrackSubscribed);

      rp.tracks.forEach((rtp) => {
        if (!rtp.isSubscribed && !rtp.track) return;

        this.attachTrack(rtp.track);
      });
    },
    onTrackSubscribed(t) {
      this.attachTrack(t);
    },
    attachTrack(t) {
      document.getElementById("video")?.appendChild(t.attach());
    },
    participantDisconnected() {
      _room.disconnect();
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.video,
.video video,
.me {
  position: fixed;
  width: 300px;
  height: 300px;
  bottom: -300vh;
  right: -300vh;
  z-index: -1;
  opacity: 0;
}

.hide {
  opacity: 0;
}
</style>
