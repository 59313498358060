<template>
  <div class="c-visitorCallModalMember">
    <img
      class="c-visitorCallModalMember_img"
      :src="img"
    />
    <div class="c-visitorCallModalMember_txt">
      <p class="c-visitorCallModalMember_txt_ja">{{ nameJa }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisitorCallModalMember",
  props: {
    nameJa: String,
    img: {
      type: String,
      default: "member_default.svg",
    },
  },
};
</script>

<style scoped lang="scss">
.c-visitorCallModalMember {
  display: flex;
  align-items: center;

  &_img {
    border-radius: 12px;
    width: 168px;
    height: 168px;
  }

  &_txt {
    margin-left: 16px;
    width: calc(100% - 180px);

    &_ja {
      font-size: 18px;
      text-align: justify;
    }

    &_en {
      @include opensans;
      font-size: 18px;
      /* 3点リーダー */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
