<template>
  <div class="whoami">
    <div class="row">
      <div class="label">Type:</div>
      <select class="value" v-model="type">
        <option value="member">member</option>
        <option value="visitor">visitor</option>
      </select>
    </div>
    <div class="row">
      <div class="label">Room:</div>
      <input class="value" type="text" v-model="me" />
    </div>
    <div class="row">
      <button class="submit" @click="submit">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "me",
  data: () => ({
    type: "",
    me: "",
  }),
  methods: {
    submit() {
      if (window.confirm(`この端末を ${(this.type === "visitor" ? "visitor" : this.me)} として保存します。`)) {
        localStorage.setItem("type", this.type);
        localStorage.setItem("me", this.me);

        this.$router.push(this.type === "visitor" ? "/" : "/member_top")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whoami {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  .row {
    display: flex;

    &:not(:first-child) {
      margin-top: 30px;
    }

    .label {
      display: flex;
      margin-right: 12px;
      width: 100px;
      justify-content: flex-end;
      align-items: center;
      font-size: 24px;
      text-align: right;
    }

    .value {
      padding: 10px 8px;
      width: 300px;
      font-size: 24px;
      border-radius: 10px;
    }

    .submit {
      display: flex;
      width: 412px;
      height: 50px;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-radius: 10px;
      font-size: 24px;
    }
  }
}
</style>
