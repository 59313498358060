<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-visitorCall">
          <VisitorCallModalJeki :nameJa="ja" :img="img" />

          <div class="l-visitorCall_btn">
            <BtnMedium
              txt="呼び出す"
              link="/visitor_call_modal_call"
            />
            <BtnSmallBack
              txt="HOMEに戻る"
              link="/"
              class="l-visitorCall_btn_back"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import VisitorCallModalJeki from "@/components/VisitorCallModalJeki.vue";
import BtnMedium from "@/components/BtnMedium.vue";
import BtnSmallBack from "@/components/BtnSmallBack.vue";

export default {
  name: "CallJeki",
  components: {
    VisitorCallModalJeki,
    BtnMedium,
    BtnSmallBack,
  },
  data: () => ({
    ja: "",
    jeki: "A",
    img: "",
  }),
  async mounted() {
    const { data } = await axios.get("/companies.json");
    const jeki = data
      .filter((x) => x.id === this.jeki)[0]
    this.ja = jeki.name;
    this.img = jeki.logo;

    localStorage.setItem("ja", this.ja);
    localStorage.setItem("en", "jeki");
    localStorage.setItem("img", this.img);
  },
};
</script>

<style scoped lang="scss"></style>
