<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-visitorCall">
          <div class="text">担当者を呼び出しました。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisitorPostDone",
  async mounted() {
    localStorage.removeItem("comp_id");
    localStorage.removeItem("comp_name");
    localStorage.removeItem("comp_logo");

    setTimeout(
      function() {
        this.$router.push("/");
      }.bind(this),
      5000
    );
  },
};
</script>

<style scoped lang="scss">
.l-visitorCall {
  position: relative;
  height: 100%;

  .text {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
}
</style>
