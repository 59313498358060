<template>
  <router-link :to="link" class="c-cardCompany">
    <img class="c-cardCompany_img" :src="img" />
    <div class="c-cardCompany_txt">
      <p class="c-cardCompany_txt_cont">{{ name }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CardCompany",
  props: {
    name: String,
    img: {
      type: String,
      default: "https://placehold.jp/200x200.png",
    },
    link: String,
  },
};
</script>

<style scoped lang="scss">
.c-cardCompany {
  display: flex;
  padding: 16px;
  border-radius: 11px;
  width: 250px;
  height: 92px;
  box-shadow: 0px 0px 6px #00000029;

  &_img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  &_txt {
    display: flex;
    align-items: center;
    margin-left: 16px;
    width: calc(100% - (60px + 16px));

    &_cont {
      font-size: 14px;
      /* 3点リーダー（2行まで） */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
