<template>
  <div class="p-visitorCompanyList">
    <HeadingBand txt="訪問先を選択してください。" />

    <div class="p-visitorCompanyList_list">
      <CardCompany
        v-for="(c, i) in companies"
        :key="i"
        :name="c.name"
        :img="c.logo"
        @click="next(c.id, c.name, c.logo)"
        link="/visitor_post"
      />
    </div>

    <div class="p-visitorMemberList_back">
      <BtnSmallBack txt="HOMEに戻る" link="/" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

import HeadingBand from "@/components/HeadingBand.vue";
import CardCompany from "@/components/CardCompany.vue";
import BtnSmallBack from "@/components/BtnSmallBack.vue";

export default {
  name: "VisitorCompanyPost",
  components: {
    HeadingBand,
    CardCompany,
    BtnSmallBack,
  },
  data: () => ({
    companies: [],
  }),
  async mounted() {
    const { data } = await axios.get("/companies.json?" + new Date().getTime());
    this.companies = data;
  },
  methods: {
    next(id, name, logo) {
      localStorage.setItem("comp_id", id);
      localStorage.setItem("comp_name", name);
      localStorage.setItem("comp_logo", logo);
    },
  },
};
</script>

<style scoped lang="scss">
.p-visitorCompanyList {
  &_list {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 50px auto 0;
    padding: 10px;
    width: 850px;
    height: 400px;

    .c-cardCompany {
      &:not(:nth-child(3n-2)) {
        margin-left: 40px;
      }

      &:nth-child(n + 4) {
        margin-top: 40px;
      }
    }
  }
}

.p-visitorMemberList {
  &_back {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>
