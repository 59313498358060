<template>
  <div class="l-wrapper">
    <Header />
    <div class="contents">
      <router-view />
    </div>
    <div class="overlay" v-if="isSound">
      <div class="modal">
        <p class="modal_text">
          受付システムを利用するには<br />
          音声再生の許可が必要です。
        </p>
        <div id="sound" class="modal_btn" @click="allow">許可する</div>
      </div>
    </div>
    <div id="log" v-if="isLogging"></div>
  </div>
</template>

<script>
import axios from "axios";

import "@/assets/css/loaders.min.css";

import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
  data: () => ({
    me: "",
    type: "",
    isSound: true,
    isLogging: false,
  }),
  mounted() {
    this.isLogging = isLogging; // eslint-disable-line

    this.me = localStorage.getItem("me");
    this.type = localStorage.getItem("type");

    document.getElementById("sound").onclick = function() {
      notify_call.load(); // eslint-disable-line
      notify_post.load(); // eslint-disable-line
      this.isSound = false;
    }.bind(this);

    if (this.type === "visitor") this.isSound = false;

    setInterval(this.main, 500);
  },
  methods: {
    async main() {
      const { data } = await axios.get("/status.json?" + new Date().getTime());

      log(JSON.stringify(data)); // eslint-disable-line

      switch (data.s) {
        case "w": // waiting
          return;
        case "cs": // call start
          if (data.t === this.me) {
            if (data.m === undefined) {
              this.$router.push(`/member_call?target=${data.t}&jeki=1`);
            } else {
              this.$router.push(
                `/member_call?target=${data.t}&member=${data.m}`
              );
            }
            this.connect();
            this.read();
          }
          break;
        case "cc": // call connected
          if (this.type === "visitor") {
            // eslint-disable-next-line no-undef
            state = "connected";
            this.read();
          }
          break;
        case "cr": // call reject
          // eslint-disable-next-line no-undef
          state = "end";
          if (this.type === "visitor") {
            this.$router.push("/");
            this.read();
          }
          break;
        case "ce": // call end
          // eslint-disable-next-line no-undef
          state = "end";
          this.read();
          break;
        case "p": // post notify
          if (this.type === "member" && this.me === data.t) {
            this.$router.push("/post_notify");
            this.read();
          }
          break;
        default:
          break;
      }
    },
    // 接続
    async connect() {
      //
    },
    // 発信
    async start(target, member) {
      this.update({
        s: "cs",
        t: target,
        m: member,
      });
    },
    // 既読
    async read() {
      this.update({
        s: "w",
      });
    },
    async update(json) {
      await axios.post("/status.php", JSON.stringify(json));
    },
  },
};
</script>

<style lang="scss">
.l-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.contents {
  width: 100%;
  height: calc(100% - 91px);
}
</style>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #000000;
    opacity: 0.78;
  }
}

.modal {
  position: absolute;
  width: 417px;
  height: 482px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 20px;
  z-index: 999;

  &_text {
    position: absolute;
    width: 100%;
    top: 130px;
    left: 0;
    text-align: center;
    font-size: 18px;
    line-height: 1.8;
  }

  &_btn {
    display: flex;
    position: absolute;
    width: calc(100% - 60px);
    height: 78px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    background-color: #1d1d1d;
    font-size: 20px;
    font-weight: bold;
    border-radius: 11px;
    justify-content: center;
    align-items: center;
  }
}

#log {
  box-sizing: border-box;
  position: fixed;
  padding: 10px;
  width: 30vw;
  height: 100vh;
  top: 0;
  right: 0;
  line-height: 1.2;
  background-color: #ffffff;
  overflow: auto;
  word-break: break-all;
  z-index: 99999;
}
</style>
